.quillCommentBox {
  overflow: hidden;
  width: 100%;
  color: #344050;
  background-color: #fff;
  display: block;
  margin-left: 0.7em;
  border: 1px solid #d8e2ef;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.quillCommentBox:focus {
  border-color: #9ec2f3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(44, 123, 229, 0.25);
}

.quillCommentBox > .ql-container > .ql-editor {
  padding: 0.5em 1.1em !important;
}
